<template>
  <div class="page-content">
    <div class="container-fluid">
      <DataTable
        :refs="refs"
        :colspan="9"
        :hasPaidCountStatistics="true"
        :hasSearchFilter="true"
        :hasCompanyFilter="isAdmin"
        :hasPrintCompanyInvoices="true"
        :hasDateFilter="true"
        :hasPaidFilter="true"
        :hasHashId="true"
        :hasMultiSelect="false"
        :hasBulkAction="true"
        :hasExport="true"
        :addNew="addNew"
        :hasPDF="true"
        :hasShowEntries="true"
        :lblSearch="$t('app.search')"
        :fields="{
          id: 'id',
          title: 'title',
          date: 'date',
          status: 'status',
        }"
        :hasUser="true"
        :image="$t('view.user')"
        :image_style="'width: 40px;height: 40px;border-radius: 50%'"
        :hasInvoiceKey="true"
        :invoice_key="$t('view.invoice_key')"
        :hasPaymentStatus="true"
        :payment_status="$t('view.payment_status')"
        :hasCompany="isAdmin"
        :company="$t('view.the_company')"
        :hasSubject="true"
        :subject="$t('view.subject')"
        :hasPrice="true"
        :price="$t('view.amount')"
        :hasPaymentDate="true"
        :hasDate="true"
        :payment_date="$t('view.payment_date')"
        :hasActions="true"
        :hasDeleteButton="true"
        :hasShareButtons="true"
        :hasReturnedVoucherButton="true"
        :actionable="true"
        :sorDataTable="true"
        v-on:hasAddNewPermission="handlePermissoinChange"
      >
      </DataTable>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import DataTable from "@/components/DataTable.vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";

export default defineComponent({
  name: "List",
  components: {
    DataTable,
  },
  data() {
    return {
      //
      addNew: false,
      refs: "invoices",
      isAdmin: localStorage.getItem("role_key") == "root" ? true : false,
    };
  },
  created() {
    setCurrentPageTitle(this.$t("nav.invoices"), "bx bx-money");
  },
  methods: {
    handlePermissoinChange(event) {
      this.addNew = event;
    },
  },
});
</script>
